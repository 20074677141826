import React from 'react';
import ContactForm from '../components/sections/ContactForm';

const Contact = () => {

  document.title = "Contact | Alston Applications"

  return (
    <ContactForm></ContactForm>
  );
}

export default Contact;