import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../components/elements/Image';
import classNames from 'classnames';
// import sections

const Confirmation = ({
    ...props
  }) => {

    document.title = "Alston Applications"

    const classes = classNames(
        'site-footer center-content-mobile'
    );

    return (
        <section
          {...props}
          className={classes}
        >
          <div className="container reveal-from-left">
            <div className={
              classNames(
                'site-footer-inner'
              )}>
              <div className="footer-top space-between text-xxs" style={{"display":"block", "font-family":"monospace"}}>
                <h2 className='text-color-secondary'>Thanks for getting in touch</h2>
                <h4 className='text-color-secondary'>We'll get back to you as soon as possible.</h4>
              </div>
              <div className="footer-bottom space-between text-xxs invert-order-desktop">
              </div>
            </div>
          </div>
        </section>
      );
}

export default Confirmation;