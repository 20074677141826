import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import background from './../../assets/images/plus.svg'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
      style={{backgroundImage:`url(${background})`, "backgroundRepeat":"repeat"}}
    >

      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content" style={{paddingBottom:"15vh", paddingTop:"15vh", textAlign:"left"}}>
            <h1 className="m-16 reveal-from-bottom" style={{"color": "#ECEDED", "fontFamily": "monospace", "fontSize": "smaller", "marginBottom":"0px", "lineHeight":"normal"}}>&#127468;&#127463; BASED SOFTWARE DEVELOPMENT COMPANY</h1>
            <h2 className="m-16 reveal-from-bottom" style={{"color": "#ECEDED", "fontFamily": "monospace", "marginTop":"0px"}}>ALSTON APPLICATIONS</h2>
            <h3 className="m-16 reveal-from-bottom" style={{"color": "#ECEDED", "fontFamily": "monospace", "marginTop":"30px"}}>Specialists in bespoke small scope software development projects</h3>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;