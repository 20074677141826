import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import Input from '../elements/Input';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-google'
import Button from '../elements/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ContactForm({
}) {
  

  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [message, setMessage] = useState(null)
  const [display, setDisplay] = useState(false)
  const [token, setToken] = useState(null)
  const [emailValid, setEmailValid] = useState(true)
  const [nameValid, setNameValid] = useState(true)
  const [messageValid, setMessageValid] = useState(true)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const classes = classNames(
    'site-footer center-content-mobile'
  );

  const onLoadRecaptcha = () => {
  }

  const verifyCallback = (result) => {
    if(result !== null) {
      setToken(result)
      const ok = checkValuesArePopulated();
      setButtonDisabled(ok);
      setDisplay(true)
    }
  }

  const checkValuesArePopulated= useCallback(()=>{
    let ok = true;

    console.log(name);

    setNameValid(name !== null && name !== "")
    ok = ok && nameValid

    setEmailValid(email !== null && email !== "" )
    ok = ok && emailValid

    setMessageValid(message !== null &&  message !== "")
    ok = ok && messageValid

    return ok;
  },[name,email,message,nameValid,emailValid,messageValid,setNameValid,setEmailValid,setMessageValid])

  useEffect(()=>{
    if(display) {
      setButtonDisabled(!checkValuesArePopulated());
    }
  },[name,email,message,display,setButtonDisabled,checkValuesArePopulated])

  const sendEmail = async() => {
    const response = await fetch("https://8g4aqqgzp3.execute-api.eu-west-2.amazonaws.com/Prod/Enquiry", {
      method: "POST",
      headers: {
          "x-api-key": "np53ixxvqt8GyhGXijpEs2bdM70EJOgT9wUy6j3O"
      },
      body: JSON.stringify({
        "fullname": name,
        "email": email,
        "message": message,
        "recaptureToken": token
      })
    }).then(data => {
        if(data.ok && data.status === 200) {
            return data.json();
        } else {
            throw "Non 200 response recieved"
        }
    });
    console.log(response)
  }

  const sendEnquiry = async () => {

    const ok = checkValuesArePopulated()
    if(!ok) {
      return
    }

    setButtonDisabled(true)

    try {
      await sendEmail();
      document.location.href = '/confirmation'
    } catch(e) {
      console.log(e)
      setButtonDisabled(false)
      toast.error("Something went wrong - please try again later", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        });
    }


    
  }

  return (
    <section
      className={classes}
    >
      <div className="container reveal-from-left">
        <div className={
          classNames(
            'site-footer-inner'
          )}>
          <div className="footer-top space-between text-xxs" style={{"display":"block", "font-family":"monospace"}}>
            <h1 className='text-color-secondary'>Contact us</h1>
            <h3 className='text-color-secondary'>Full name</h3>
            <Input type="name" name="name" valid={nameValid} id="name" placeholder={"Sam Smith"} autoComplete="off" onChange={evt => setName(evt.target.value)}></Input>
            <h3 className='text-color-secondary'>Email</h3>
            <Input type="email" name="email" valid={emailValid} placeholder={"sam.smith@gmail.com"} autoComplete="off" onChange={evt => setEmail(evt.target.value)}></Input>
            <h3 className='text-color-secondary'>Message</h3>
            <Input valid={messageValid} rows={10} type={"textarea"} placeholder={""} onChange={evt => setMessage(evt.target.value)}></Input>
            <div style={{"marginBottom":"30px"}}></div>
            <ReCaptcha
                size="normal"
                data-theme="dark"            
                render="explicit"
                sitekey="6Le0uoAeAAAAAPrnIBq3z4Qo9A4_lDFudOJa5Yke"
                onloadCallback={onLoadRecaptcha}
                verifyCallback={res => verifyCallback(res, name, email, message, nameValid, emailValid, messageValid)}
            />
            <Button disabled={buttonDisabled} style={{"marginTop":"30px", "display": display ? "block" : "none"}} className="button button-primary button-wide-mobile button-sm" onClick={() => {sendEnquiry()}}>SEND</Button>
          </div>
          <ToastContainer />
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
          </div>
        </div>
      </div>
    </section>
  );
}